import React from 'react';

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Transaction from "./pages/Transaction";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";

import {isAuth} from './services/auth';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";
import Chart from "./pages/Chart";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function App() {
  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/account">
              <Account />
            </Route>
            <Route path="/">
              {isAuth() ?
                  <Dashboard title={"Tableau de bord"}>
                      <Grid container spacing={3}>
                        {/* Chart */}
                        <Grid item xs={12} md={8} lg={9}>
                          <Paper className={fixedHeightPaper}>
                            <Chart/>
                          </Paper>
                        </Grid>
                        {/* Recent Deposits */}
                        <Grid item xs={12} md={4} lg={3}>
                          <Paper className={fixedHeightPaper}>
                            <h1>Montant</h1>
                            <p><span style={{marginRight: ".5em", fontSize: 70, fontWeight: 'bold'}}>***</span>TNCoins</p>
                          </Paper>
                        </Grid>
                        {/* Recent Orders */}
                        <Grid item xs={12}>
                          <Paper className={classes.paper}>
                            <Transaction />
                          </Paper>
                        </Grid>
                      </Grid>
                  </Dashboard> :
                <Login />}
            </Route>
          </Switch>
        </div>
      </Router>
    </>
  );
}

export default App;