import React from "react";


export default function Transaction() {

  return (
    <>
      <h1>Transactions</h1>
    </>
  );
}
