import React from "react"
import {Box, Link, Typography} from "@material-ui/core";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright • '}
        <Link color="inherit" href="https://www.linkedin.com/in/pierre-caro-976384174/">
            Pierre Caro
        </Link>{' | '}
        <Link color="inherit" href="http://enzosabry.fr/">
            Enzo Houadef
        </Link>{' | '}
        <Link color="inherit" href="https://www.linkedin.com/in/maximeserrano/">
            Maxime Serrano
        </Link>{' | '}
        <Link color="inherit" href="https://www.linkedin.com/in/somnypierre/">
            Pierre Somny
        </Link>{' • '}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  
  return (
    <>
      <Box mt={5} style={{display: "flex", flexGrow: 1, alignSelf: 'center', alignItems: "flex-end", marginBottom: "1.5em"}}>
        <Copyright />
      </Box>
    </>
  );
}
