import React, {useEffect, useState} from "react";
import Dashboard from "./Dashboard";
import {changePassword, getUser} from "../services/auth";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    category: {
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        "&>h2": {
            marginRight: "1em",
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Account() {
    const [lname, setLname] = useState('');
    const [fname, setFname] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [error, setError] = useState();

    const classes = useStyles();
    const history = useHistory();

    // Exécuté à la génération du composant, récupère les données user
    useEffect(() => {
        getUser().then(r => {
            console.log(r.data);
            setLname(r.data.lname);
            setFname(r.data.fname);
            setUsername(r.data.username);
            setEmail(r.data.email);
            setAddress(r.data.address);
        }).catch(console.log);
    }, []);

    const sendForm = (e) => {
        e.preventDefault();
        setError(undefined);
        if(newPassword!==newPassword2) return setError("Les deux mots de passe doivent correspondre");
        changePassword(password, newPassword).then(()=> {
            history.push('/');
        }).catch(e=>setError("Le mot de passe est incorrect"));
    };

    return (
        <Dashboard title={"Compte"}>
            <Grid container spacing={3}>
                {/* Details */}
                <Grid item container xs={8}>
                    <Paper className={classes.paper}>
                        <h1>Détails du compte</h1>
                        <Grid item className={classes.category}>
                            <h2>Nom & Prénom : </h2>
                            <p> {lname} {fname}</p>
                        </Grid>

                        <Grid item className={classes.category}>
                            <h2>Nom d'utilisateur : </h2>
                            <p> {username}</p>
                        </Grid>

                        <Grid item className={classes.category}>
                            <h2>Adresse email :</h2>
                            <p> {email}</p>
                        </Grid>

                        <Grid item className={classes.category}>
                            <h2>Adresse du wallet : </h2>
                            <p> {address}</p>
                        </Grid>
                    </Paper>
                </Grid>
                {/* Pasword change */}
                <Grid item container xs={4}>
                    <Paper className={classes.paper}>
                        <h1>Changer de mot de passe</h1>
                        <form method={"post"} className={classes.form} noValidate>
                            <TextField
                                error={!!error && !error?.startsWith("Les")}
                                helperText={(error && !error?.startsWith("Les")) && error}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                label="Mot de passe actuel"
                                name="password"
                                type={"password"}
                                autoComplete="password"
                                autoFocus
                                onChange={v => setPassword(v.currentTarget.value)}
                            />
                            <TextField
                                error={!!error && error.startsWith("Les")}
                                helperText={(error && error?.startsWith("Les")) && error}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="newPassword"
                                label="Nouveau mot de passe"
                                type="password"
                                id="newPassword"
                                autoComplete="current-password"
                                onChange={v => setNewPassword(v.currentTarget.value)}
                            />
                            <TextField
                                error={!!error && error.startsWith("Les")}
                                helperText={(error && error?.startsWith("Les")) && error}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="newPassword2"
                                label="Nouveau mot de passe"
                                type="password"
                                id="newPassword2"
                                autoComplete="current-password"
                                onChange={v => setNewPassword2(v.currentTarget.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={sendForm}
                            >
                                Changer de mot de passe
                            </Button>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Dashboard>
    );
}