import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {addUser} from "../services/auth";
import {useHistory} from "react-router-dom";
import Footer from "./Footer";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState();
  const classes = useStyles();
  const history = useHistory();

  const sendForm = (e) => {
    e.preventDefault();
    addUser(fname, lname, username, email, password).then(user => {
      console.log(user);
      setError(user.message);
      history.push('/');
    });
  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Inscription
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="Prénom"
                    autoFocus
                    onChange={(e) => setFname(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Nom"
                    name="lastName"
                    autoComplete="lname"
                    onChange={(e) => setLname(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    error = {!!error}
                    helperText={error}
                    variant="outlined"
                    required
                    fullWidth
                    id="username"
                    label="Pseudo"
                    name="username"
                    autoComplete="username"
                    onChange={(e) => setUsername(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    error = {!!error}
                    helperText={error}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Addresse email"
                    name="email"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Mot de passe"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.currentTarget.value)}
                />
              </Grid>
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={sendForm}
                disabled={!email || !password || !username || !lname || !fname }
            >
              S'inscrire
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Vous avez déjà un compte ? Connectez-vous
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Footer />
      </Container>
  );
}