import {sha3_512} from 'js-sha3';
import axios from 'axios';

const host = "https://api.tncoin.enzosabry.fr";

export function isAuth() {
    return (sessionStorage.getItem('token') || false);
}

export async function checkLogin(email, password) {
    let hash = sha3_512(password);
    // API - GET USER
    let data;
    try {
        let res = await axios.get(`${host}/auth/${email}/${hash}`);
        data = res.data;
        console.log(data);
        if (data.token) sessionStorage.setItem('token', data.token);
    } catch (e) {
        return e.response.data;
    }
    return data;
}

export async function addUser(fname, lname, username, email, password) {
    let hash = sha3_512(password);
    let data;
    try {
        let res = await axios.post(`${host}/user`, {fname, lname, username, email, password: hash});
        data = res.data;
        console.log(data);
    } catch (e) {
        return e.response.data;
    }
    return data;
}

export function getUser() {
    console.log(`Bearer ${sessionStorage.getItem('token')}`);
    return axios.get(`${host}/user`, {
        headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
        }
    });
}

export function changePassword(password, newPassword) {
    return axios.post(`${host}/user/password`,
        {password: sha3_512(password), newPassword: sha3_512(newPassword)},
        {headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}});
}
